import React from 'react';
import { Grid, DialogTitle, Dialog, Typography, Button, Select, MenuItem, FormControl, TextField } from '@material-ui/core';
import axios from 'axios';
import '../assets/css/Style.css';

const php_url = "http://localhost/sistemachamados/";
//const php_url = "http://app.unimedsobral.com.br/chamados/api";

function SimpleDialog(props) {
    const arr = ['', 'Aguardando', 'Pendente', 'Cancelado', 'Concluído'];
    const { onClose, selectedValue, open } = props;
    const [responsavel, setResponsavel] = React.useState(props.responsaveis.indexOf(props.responsavel));
    const [status, setStatus] = React.useState(arr.indexOf(props.status));
    const [anotacao, setAnotacao] = React.useState(props.anotacao);

    const handleChangeResponsavel = (value) => {
        setResponsavel(value);
        props.intervalo();
    };

    const handleChangeStatus = (value) => {
        setStatus(value);
        props.intervalo();
    };
    
    const handleChangeAnotacao = (value) => {
        setAnotacao(value);
        props.intervalo();
    };

    const handleClose = () => {
        onClose(selectedValue);
        props.intervalo();
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} fullWidth={true}>
            <DialogTitle id="simple-dialog-title">
                <Typography className="tipographyTexto">Informações completas</Typography>
            </DialogTitle>
            <Grid className="GridInfoChamado" container item spacgin={10} xs={12}>
                <Grid container item direction="column" xs={9}>
                    <Typography className="tipographyTexto"><b>Prestador: </b></Typography>
                    <Typography>{props.prestador}</Typography>
                    <Typography className="tipographyTexto"><b>Solicitante: </b></Typography>
                    <Typography>{props.solicitante}</Typography>
                    <Typography className="tipographyTexto"><b>Email: </b></Typography>
                    <Typography>{props.email}</Typography>
                    <Typography className="tipographyTexto"><b>Telefone: </b></Typography>
                    <Typography>{props.telefone}</Typography>
                    <Typography className="tipographyTexto"><b>Problema: </b></Typography>
                    <Typography>{props.problema}</Typography>
                    <Typography className="tipographyTexto"><b>Data de abertura: </b></Typography>
                    <Typography>{props.data}</Typography>
                    <Typography className="tipographyTexto"><b>Descrição do problema:</b></Typography>
                    <Typography>{props.descricao}</Typography>
                </Grid>
                <Grid container item direction="column" xs={3}>
                    <Typography className="tipographyTexto"><b>Nº Protocolo: </b></Typography>
                    <Typography>{props.protocolo}</Typography>
                    <Typography className="tipographyTexto"><b>Responsável: </b></Typography>
                    <Typography>{props.responsavel}</Typography>
                    <Typography className="tipographyTexto"><b>Status: </b></Typography>
                    <Typography>{props.status}</Typography>
                </Grid>
            </Grid>
            <Grid className="GridInfoChamado" container item xs={12}>
                <Grid item xs={4}>
                    <FormControl>
                        <Typography className="tipographyTexto"><b>Responsável</b></Typography>
                        <Select className="selectFiltro" id="responsavel" value={responsavel} fullWidth={true} variant="outlined"
                            onChange={(e) => handleChangeResponsavel(e.target.value)}
                        >
                            {props.responsaveis.map((value, i) => (
                                <MenuItem value={i} key={i}>{value}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl>
                        <Typography className="tipographyTexto"><b>Status</b></Typography>
                        <Select className="selectFiltro" id="status" value={status} variant="outlined"
                            onChange={(e) => handleChangeStatus(e.target.value)}
                        >
                            <MenuItem value={1}>Aguardando</MenuItem>
                            <MenuItem value={2}>Pendente</MenuItem>
                            <MenuItem value={3}>Cancelado</MenuItem>
                            <MenuItem value={4}>Concluído</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <Button className="button" variant="outlined" color="primary" onClick={() => document.getElementById('formOrdem').submit()}>Alterar</Button>
                </Grid>
                <Grid item xs={8}>
                    <br/>
                    <TextField
                        style={{resize: 'none', width: '370px', height: '100px'}}
                        onChange={(e) => handleChangeAnotacao(e.target.value)}
                        value={anotacao}
                        variant="outlined"
                        multiline
                        rows={4}
                        size="small"
                    />
                </Grid>
            </Grid>
            <form name="formOrdem" id="formOrdem" action={php_url + 'alterar_chamado.php'} method="POST">
                <input type="hidden" name="protocolo" value={props.protocolo}/>
                <input type="hidden" name="responsavel" value={responsavel}/>
                <input type="hidden" name="status" value={status}/>
                <input type="hidden" name="anotacao" value={anotacao}/>
            </form>
        </Dialog>
    );
}

export default function SimpleDialogDemo(props) {
    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState();
    const [visto, setVisto] = React.useState(props.visto);

    const handleClickOpen = () => {
        setOpen(true);
        setVisto(1);
        props.intervalo();

        let form = new FormData();
        form.append('protocolo', props.protocolo);

        if (!visto) {
            axios({
                method: 'post',
                url: (php_url + 'salvar_visualizacao.php'),
                data: form,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((response) => {
                console.log(response);
            })
            .catch((response) => {
                console.log(response);
            })
        }
    };

    const handleClose = (value) => {
        setOpen(false);
        setSelectedValue(value);
        props.intervalo();
    };

    return (
        <>
            <Grid className={visto ? "GridChamado" : "GridChamadoNaoVisto"} container item onClick={handleClickOpen}>
                <Grid container item direction="column" xs={8}>
                    <Typography className="tipographyTexto"><b>Prestador: </b>{props.prestador}</Typography>
                    <Typography className="tipographyTexto"><b>Solicitante: </b>{props.solicitante}</Typography>
                    <Typography className="tipographyTexto"><b>Email: </b>{props.email}</Typography>
                    <Typography className="tipographyTexto"><b>Telefone:</b>{props.telefone}</Typography>
                    <Typography className="tipographyTexto"><b>Problema: </b>{props.problema}</Typography>
                </Grid>
                <Grid item xs={1}/>
                <Grid container item direction="column" xs={3}>
                    <Typography className="tipographyTexto"><b>Nº Protocolo: </b>{props.protocolo}</Typography>
                    <Typography className="tipographyTexto"><b>Responsável: </b>{props.responsavel}</Typography>
                    <Typography className="tipographyTexto"><b>Status: </b>{props.status}</Typography>
                    <Typography className="tipographyTexto"><b>Data de abertura: </b>{props.data}</Typography>
                </Grid>
            </Grid>
            <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose}
                protocolo={props.protocolo}
                prestador={props.prestador}
                solicitante={props.solicitante}
                email={props.email}
                telefone={props.telefone}
                problema={props.problema}
                descricao={props.descricao}
                responsavel={props.responsavel}
                anotacao={props.anotacao}
                status={props.status}
                data={props.data}
                responsaveis={props.responsaveis}
                intervalo={props.intervalo}
            />
        </>
    );
}