import React from 'react';
import axios from 'axios';
import { FormControl, Grid, MenuItem, Select, Typography } from '@material-ui/core';
import SimpleDialogDemo from '../components/modal';
import '../assets/css/Style.css';
import logo from '../assets/imgs/logo.png';
import Track from '../assets/sounds/Track_1.mp3';

const php_url = "http://localhost/sistemachamados/";
const react_url = "http://localhost:3000/";
//const php_url = "http://app.unimedsobral.com.br/chamados/api";
//const react_url = "http://app.unimedsobral.com.br/chamados/";

const meses = [
    'Todos',
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
];

class Ordens extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            load_chamados: true,
            load_responsaveis: true,
            chamados: [],
            responsaveis: [],
            data: new Date(),
            status: 1,
            responsavel: -1,
            ano: 0,
            mes: 0,
            intervalo: null,
        }

    }

    listarChamados = (status, responsavel, ano, mes, tocar_audio) => {
        this.setState({ load_chamados: true });
        let form = new FormData();
        form.append('status', status);
        form.append('responsavel', responsavel);
        form.append('ano', ano);
        form.append('mes', mes);
        axios({
            method: 'post',
            url: (php_url + 'listar_chamados.php'),
            data: form,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {
            console.log(response.data)
            if (response.data[0] === true) {
                let aux = response.data;
                const audio = new Audio(Track);
                aux.shift();

                for (let i = 0; i < aux.length; i++) {
                    if (tocar_audio === true && aux[i].visto === 0) {
                        audio.play();
                    }
                }

                this.setState({load_chamados: false, chamados: aux});
            } else {
                alert("Nenhum chamado foi encontrado!");
                this.setState({load_chamados: false, chamados: []});
            }
        })
        .catch((response) => {
            console.log(response)
        })
    }

    listarResponsaveis = () => {
        axios({
            method: 'post',
            url: (php_url + 'listar_responsaveis.php'),
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {
            console.log(response);
            if (response.status === 200) {
                let arr = response.data;
                arr.unshift('Nenhum');
                this.setState({load_responsaveis: false, responsaveis: arr});
                
            } else {
                alert("Erro ao tentar conectar ao servidor!");
            }
        })
        .catch((response) => {
            console.log(response)
        })
    }

    montarAnos = (data) => {
        let aux = [];
        for (let i = 2021; i <= data; i++) {
            aux.push(<MenuItem value={i}>{i}</MenuItem>)
        }

        return aux;
    }

    handleChangeStatus = (value) => {
        this.setState({ status: value }, this.listarChamados(value, this.state.responsaveis, this.state.ano, this.state.mes, false));
    }
    handleChangeResponsavel = (value) => {
        this.setState({ responsavel: value }, this.listarChamados(this.state.status, value, this.state.ano, this.state.mes, false));
    }
    handleChangeAno = (value) => {
        this.setState({ ano: value }, this.listarChamados(this.state.status, this.state.responsaveis, value, this.state.mes, false));
    }
    handleChangeMes = (value) => {
        this.setState({ mes: value }, this.listarChamados(this.state.status, this.state.responsaveis, this.state.ano, value, false));
    }

    componentDidMount() {
        let md5 = require("md5");
        let string = sessionStorage.getItem('@chamados/string');
        let data = sessionStorage.getItem('@chamados/data');
        let numero = sessionStorage.getItem('@chamados/numero');

        if (string && data && numero) {
            if (string !== md5(parseInt(data) + parseInt(numero))) {
                window.location = react_url + "login";
            }
        } else {
            window.location = react_url + "login";
        }
        
        //this.setState({ ano: this.state.data.getFullYear(), mes: this.state.data.getMonth() + 1 });
        this.listarChamados(1, -1, 0, 0);
        this.listarResponsaveis();
        
        let intervalo = setInterval(() => {this.listarChamados(1, -1, 0, 0, true)}, 300000);
        this.setState({ intervalo: intervalo });
    }

    reiniciarIntervalo = () => {
        clearInterval(this.state.intervalo);
        let intervalo = setInterval(() => {this.listarChamados(1, -1, 0, 0, true)}, 300000);
        this.setState({ intervalo: intervalo });
    }

    render() {

        return (
            <div className="divPrincipal">
            <Grid className="centralizar" container alignItems="center" justify="center">
                <Grid className="GridLogo" container item xs={12} alignItems="center" justify="center">
                    <img src={logo} alt='' height="80px"/>
                </Grid>
                <br/>
                <Grid className="GridHome" container item xs={12} sm={10}>
                    <>
                    <Grid className="GridFiltros" container item direction="column" alignItems="center" xs={2}>
                        <br/>
                        <Typography className="tipographyTexto">Status</Typography>
                        <FormControl>
                            <Select className="selectFiltro" variant="outlined"
                                value={this.state.status}
                                disabled={this.state.load_responsaveis || this.state.load_chamados}
                                onChange={(e) => {
                                    this.handleChangeStatus(e.target.value)
                                }}
                                onClick={() => this.reiniciarIntervalo()}
                            >
                                <MenuItem value={0}>Todos</MenuItem>
                                <MenuItem value={1}>Aguardando</MenuItem>
                                <MenuItem value={2}>Pendente</MenuItem>
                                <MenuItem value={3}>Cancelado</MenuItem>
                                <MenuItem value={4}>Concluído</MenuItem>
                            </Select>
                        </FormControl>
                        <br/>
                        <Typography className="tipographyTexto">Responsável</Typography>
                        <FormControl>
                            <Select className="selectFiltro" variant="outlined"
                                value={this.state.responsavel}
                                disabled={this.state.load_responsaveis || this.state.load_chamados}
                                onChange={(e) => {
                                    this.handleChangeResponsavel(e.target.value)
                                }}
                                onClick={() => this.reiniciarIntervalo()}
                            >
                                <MenuItem value={-1}>Todos</MenuItem>
                                {!this.state.load_responsaveis && this.state.responsaveis.map((value, i) => (
                                    <MenuItem value={i} key={i}>{value}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <br/>
                        <Typography className="tipographyTexto">Ano</Typography>
                        <FormControl>
                            <Select className="selectFiltro" variant="outlined"
                                value={this.state.ano}
                                disabled={this.state.load_responsaveis || this.state.load_chamados}
                                onChange={(e) => {
                                    this.handleChangeAno(e.target.value)
                                }}
                                onClick={() => this.reiniciarIntervalo()}
                            >
                                <MenuItem value={0}>Todos</MenuItem>
                                {this.montarAnos(this.state.data.getFullYear())}
                            </Select>
                        </FormControl>
                        <br/>
                        <Typography className="tipographyTexto">Mês</Typography>
                        <FormControl>
                            <Select className="selectFiltro" variant="outlined"
                                value={this.state.mes}
                                disabled={this.state.load_responsaveis || this.state.load_chamados}
                                onChange={(e) => {
                                    this.handleChangeMes(e.target.value)
                                }}
                                onClick={() => this.reiniciarIntervalo()}
                            >
                                {meses.map((value, i) => (<MenuItem value={i} key={i}>{value}</MenuItem>))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid className="GridChamados" container item direction="column" xs={10}>
                        {this.state.load_responsaveis && this.state.load_chamados &&
                        <Typography>
                            Aguarde
                        </Typography>
                        }
                        {!this.state.load_responsaveis && !this.state.load_chamados && this.state.chamados.map((value, i) => (
                            <SimpleDialogDemo key={i}
                                protocolo={value.protocolo}
                                prestador={value.prestador}
                                solicitante={value.solicitante}
                                email={value.email}
                                telefone={value.telefone}
                                problema={value.problema}
                                descricao={value.descricao}
                                responsavel={value.responsavel}
                                anotacao={value.anotacao}
                                status={value.status}
                                data={value.data}
                                visto={value.visto}
                                responsaveis={this.state.responsaveis}
                                intervalo={() => this.reiniciarIntervalo()}
                            />
                        ))}
                    </Grid>
                    </>
                </Grid>
                
            </Grid>
        </div>
        );
    };
}

export default Ordens;