import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Finalizado from "./views/finalizado";
import Login from "./views/login";
import OrdemServico from "./views/ordemservico";
import Ordens from "./views/ordens";
import Page404 from './views/Page404';

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path='*' component={OrdemServico}/>
      {/*<Route exact path='/finalizado' component={Finalizado}/>*/}
      {/*<Route exact path='/login' component={Login}/>*/}
      {/*<Route exact path='/ordens' component={Ordens}/>*/}
      {/*<Route path='*' component={Page404}/>*/}
    </Switch>
  </BrowserRouter>
);

export default Routes;