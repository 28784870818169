import React from 'react';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import '../assets/css/Style.css';
import md5 from 'md5';
import logo from '../assets/imgs/logo.png';

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            not_autorizado: false,
            senha: '',
        }
    }

    handleChangeSenha = (value) => {
        this.setState({ senha: value });
    }

    login = () => {
        if (md5(this.state.senha) === '60301bc8287bfb3d2ca395a86834ca42') {
            let numero = 1;
            let data = Date.now();
            let string = parseInt(numero) + parseInt(data);
            sessionStorage.setItem('@chamados/string', md5(string));
            sessionStorage.setItem('@chamados/data', data);
            sessionStorage.setItem('@chamados/numero', numero);
            window.location = window.location.origin + '/ordens';
        } else {
            alert("Senha errada!");
        }
    }

    componentDidMount() {
        let md5 = require("md5");
        let string = sessionStorage.getItem('@chamados/string');
        let data = sessionStorage.getItem('@chamados/data');
        let numero = sessionStorage.getItem('@chamados/numero');
        console.log(md5(parseInt(data) + parseInt(numero)));
        if (string && data && numero) {
            if (string === md5(parseInt(data) + parseInt(numero))) {
                window.location = window.location.origin + '/ordens';
            } else {
                this.setState({ not_autorizado: true });
            }
        } else {
            this.setState({ not_autorizado: true });
        }
    }

    render() {
        return (
        <>
        {this.state.not_autorizado && 
        <div className="divPrincipal">
            <Grid className="centralizar" container alignItems="center" justify="center">
                <Grid className="GridLogo" container item xs={12} alignItems="center" justify="center">
                    <img src={logo} alt='' height="80px"/>
                </Grid>
                <Grid container item xs={8} alignItems="center" justify="center" spacing={3}>
                    <Grid item xs={12}>
                        <Typography className="tipographyTitulo" variant="h3" align="center">Login</Typography>
                    </Grid>
                    <Grid item xs={12} container alignItems="center" justify="center" direction="column">
                        <Typography className="tipographyTexto" align="center">Senha do administrador</Typography>
                        <form onSubmit={(e) => e.preventDefault()}>
                            <TextField type="password" value={this.state.senha} variant="outlined" size="small" name="senha" style={{width: '300px'}}
                                onChange={(e) => this.handleChangeSenha(e.target.value)}
                            />
                        </form>
                    </Grid>
                    <Grid item xs={12} container alignItems="center" justify="center">
                        <Typography align="center">
                            <Button className="button" align="center" variant="outlined" onClick={() => this.login()}>Entrar</Button>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </div>}
        </>
        );
    };
}

export default Login;