import React from 'react';
import { Backdrop, CircularProgress, Typography } from '@material-ui/core';

const CustomBackdrop = (props) => {
	return (
		<Backdrop open={props.open} style={{zIndex: 1000}}>
            <div style={{width: '80vw', textAlign: 'center'}}>
                <Typography variant="h4" style={{color: 'white'}}>
                    Aguarde um momento...
                </Typography>
                <CircularProgress color="secondary"/>
            </div>
        </Backdrop>
	)
} 

export default CustomBackdrop;