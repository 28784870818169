import React, { useEffect } from 'react';
import { Button, Grid, MenuItem, Select, TextField, Typography, FormControl, FormHelperText, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';
import CustomBackdrop from '../components/CustomBackdrop';
import apiChamados from '../services/apiChamados';
import logo from '../assets/imgs/logo.png';
import '../assets/css/Style.css';

const maskTelefone = (value) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})/, '($1')
        .replace(/(\(\d{2})(\d)/, '$1) $2')
        .replace(/(\))(\d)/, '$1 $2')
        .replace(/(\s\d{4})(\d+)$/, '$1-$2')
        .replace(/(\s)(\d)(\d{3})(-)(\d)(\d{3})(\d)+?/, '$1$2$3$5$4$6$7')
        .replace(/(\d{5}-\d{4})\d+?$/, '$1')
}

const maskEmail = (value) => {
    return value.match(/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/g);
}

const OrdemServico = (props) => {
    const [state, setState] = React.useState({
        captcha: '',
        solicitante: '',
        prestador: '',
        email: '',
        telefone: '',
        id_problema: 0,
        tipo_acesso: 0,
        cod_acesso: '',
        descricao: '',
        problemas: [],
        backdrop_open: false,
    });
    const [dialog, setDialog] = React.useState({
        open: false,
        title: '',
        content: '',
        reload: false,
    });
    const [errors, setErrors] = React.useState({
        solicitante: false,
        prestador: false,
        email: false,
        telefone: false,
        id_problema: false,
        descricao: false,
        solicitanteText: '',
        prestadorText: '',
        emailText: '',
        telefoneText: '',
        id_problemaText: '',
        descricaoText: '',
    });

    const verificarCampos = () => {
        let retorno = true;
        let solicitante = false;
        let prestador = false;
        let email = false;
        let telefone = false;
        let id_problema = false;

        if (state.solicitante.length < 3) {
            retorno = false;
            solicitante = true;
        }

        if (state.prestador.length < 3) {
            retorno = false;
            prestador = true;
        }

        let mask_email = maskEmail(state.email);
        if (mask_email === null || mask_email.length < 1) {
            retorno = false;
            email = true;
        }

        if (state.telefone.length < 14) {
            retorno = false;
            telefone = true;
        }

        if (state.id_problema === 0) {
            retorno = false;
            id_problema = true;
        }

        setErrors({...errors,
            solicitante: solicitante,
            prestador: prestador,
            email: email,
            telefone: telefone,
            id_problema: id_problema,
        });

        return retorno;
    }

    const handleSubmitForm = () => {
        if (verificarCampos()) {
            let formData = new FormData();
            formData.append('g-recaptcha-response', state.captcha);
            formData.append('solicitante', state.solicitante);
            formData.append('prestador', state.prestador);
            formData.append('descricao', state.descricao);
            formData.append('solicitacao', state.id_problema);
            formData.append('email', state.email);
            formData.append('telefone', state.telefone);
            formData.append('tipo_acesso', state.tipo_acesso);
            formData.append('cod_acesso', state.cod_acesso);

            apiChamados.get(
                'salvar.php', formData
            ).then((response) => {
                console.log(response);

                if (response.status === 200) {
                    setDialog({...dialog,
                        open: true,
                        title: 'Formulário salvo com sucesso!',
                        content: 'Seu número de protocolo é '+ response.data.protocolo +'. Uma cópia do seu formulário foi enviada ao seu email. Em breve entraremos em contato por um dos meios informados.',
                        reload: true,
                    });
                }

            }).catch((err) => {
                console.log(err);
                console.log(err.response);

                if (err.response.status === 422) {
                    setDialog({...dialog,
                        open: true,
                        title: 'Ocorreu um erro!',
                        content: 'Preencha todos os campo corretamente antes de enviar o formulário',
                    });
                } else if (err.response.status === 500) {
                    setDialog({...dialog,
                        open: true,
                        title: 'Ocorreu um erro!',
                        content: 'Não foi possível realizar esta ação. Tente novamente ou entre em contato com o administrador do sistema.'
                    });
                }
            });
        }
    }

    return (
        <div className="divPrincipal">
            <Grid className="centralizar" container alignItems="center" justify="center">
                <Grid item container xs={12} justify="center">
                    <Typography className="tipographyTitulo" variant="h4">Ordem de Serviço</Typography>
                </Grid>
                <Grid item xs={12}><br/></Grid>
                <Grid container item xs={8} justify="center" spacing={3}>
                    <Grid container item xs={12} justify="center" spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <Grid item xs={12}>
                                <Typography className="labelInput">Nome do Solicitante*</Typography>
                                <TextField value={state.solicitante} variant="outlined" size="small" name="solicitante" error={errors.solicitante} helperText={errors.solicitanteText} fullWidth
                                    onChange={(e) => {setState({...state, solicitante: e.target.value})}}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className="labelInput">Nome do Prestador*</Typography>
                                <TextField value={state.prestador} variant="outlined" size="small" name="prestador" error={errors.prestador} helperText={errors.prestadorText} fullWidth
                                    onChange={(e) => {setState({...state, prestador: e.target.value})}}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className="labelInput">Email*</Typography>
                                <TextField value={state.email} variant="outlined" size="small" name="email" error={errors.email} helperText={errors.emailText} fullWidth
                                    onChange={(e) => {setState({...state, email: e.target.value})}}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className="labelInput">Telefone* (preferencialmente Whatsapp)</Typography>
                                <TextField value={state.telefone} variant="outlined" size="small" name="telefone" error={errors.telefone} helperText={errors.telefoneText} fullWidth
                                    onChange={(e) => {setState({...state, telefone: maskTelefone(e.target.value)})}}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className="labelInput">Selecione o tipo de solicitação*</Typography>
                                <FormControl size="small" variant="outlined" error={errors.id_problema} fullWidth>
                                    <Select value={state.id_problema}  name="id_problema"
                                        onChange={(e) => {setState({...state, id_problema: e.target.value})}}
                                    >   
                                        <MenuItem value={0}>Selecione uma opção</MenuItem>
                                        <MenuItem value={'Problema no leitor biométrico'}>Problema no leitor biométrico</MenuItem>
                                        <MenuItem value={'Problema no Sistema Autorizador'}>Problema no Sistema Autorizador</MenuItem>
                                        <MenuItem value={'Solicitação de cadastro de atendente'}>Solicitação de cadastro de atendente</MenuItem>
                                        <MenuItem value={'Problema no Sistema PEP Unimed'}>Problema no Sistema PEP Unimed</MenuItem>
                                    </Select>
                                    <FormHelperText>{errors.id_problemaText}</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Grid item xs={12}>
                                <Typography className="labelInput">Tipo de Acesso remoto (opcional)</Typography>
                                <FormControl size="small" variant="outlined" fullWidth>
                                    <Select value={state.tipo_acesso} name="id_problema"
                                        onChange={(e) => {setState({...state, tipo_acesso: e.target.value})}}
                                    >   
                                        <MenuItem value={0}>Selecione uma opção</MenuItem>
                                        <MenuItem value={'Anydesk'}>Anydesk</MenuItem>
                                        <MenuItem value={'TeamViewer'}>TeamViewer</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className="labelInput">Código do Acesso remoto (opcional)</Typography>
                                <TextField disabled={state.tipo_acesso === 0} value={state.cod_acesso} variant="outlined" size="small" name="telefone" fullWidth
                                    onChange={(e) => {setState({...state, cod_acesso: e.target.value})}}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className="labelInput">Descreva detalhadamente o problema (opcional)</Typography>
                                <TextField value={state.descricao} multiline rows={5} variant="outlined" size="small" name="descricao" error={errors.descricao} helperText={errors.descricaoText} fullWidth
                                    onChange={(e) => {setState({...state, descricao: e.target.value})}}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} justify="center">
                        <ReCAPTCHA
                            // sitekey="6Le60h8dAAAAAIhwQfjUTn9mCLfY-BQEpkCvJWlv"
                            sitekey="6LdeDcscAAAAAIACpCtJDpSHtnFiMOFwtsJebeZH"
                            onChange={(e) => setState({...state, captcha: e})}
                        />
                    </Grid>
                    <Grid container item xs={12} justify="center">
                        <Button className="button" variant="outlined" color="primary" onClick={handleSubmitForm}> Enviar </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Dialog open={dialog.open}>
                <DialogTitle>{dialog.title}</DialogTitle>
                <DialogContent>{dialog.content}</DialogContent>
                <DialogActions>
                    <Button className="button" variant="outlined" color="primary"
                        onClick={() => {
                            window.location.reload();
                            setDialog({...dialog, open: false});
                        }}
                    >
                        Entendi
                    </Button>
                </DialogActions>
            </Dialog>
            <CustomBackdrop open={state.backdrop_open} />
        </div>
    );
}

export default OrdemServico;