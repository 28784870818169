import React from 'react';
import './App.css';
import Routes from './routes';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#00995D' }, //'#43a048'#00995D
    secondary: { main: '#FFFFFF'},
  },
}, ptBR);

class App extends React.Component {    
  render(){
    return (
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
    );
  }
}
export default App;
